@import '../scss/variables';

.tab-item-list {
    
    button {
        outline-color: var(--main-theme-color);
        border: 10px;
    }
    
}

input[type=date] {
    outline: none;
    border: 0;
    padding: 14px 20px;
    width: 100%;
    border-radius: 5px 0px 0px 5px;
}