:root {
    --main-theme-color: #81d1d0;
    --white-theme-color: #fff;
    --gray-theme-color: #6E6B7B;
    --black-theme-color: "#00000";
    --text-muted-color: #dee2e6;
    --bs-body-font-size: 1rem;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  }